import { createSlice } from '@reduxjs/toolkit';
import { EmployeeType, PaginationPropsType } from 'gazprom-common-lib';

export const SLICE_NAME = 'communityParticipantsSlice';

export type TCommunityParticipantsSliceState = PaginationPropsType & {
  participants: {
    id: string;
    communityId: string;
    employee: EmployeeType
    isBlocked: boolean;
    reasonBlock?: string;
    creator: string
    editor: string
    createdAt: string
    updatedAt: string
    isModerator: boolean
  }[];
};

const DEFAULT_PAGINATION = { page: 1, size: 10 };

const initialState: TCommunityParticipantsSliceState = {
  participants: [],
  ...DEFAULT_PAGINATION,
};

export const communityParticipantsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPaginationAndParticipants(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
      state.participants = [];
    },
    addCommunityParticipants(state, action) {
      state.participants = state.participants.concat(action.payload);
    },
    removeParticipant(state, action) {
      state.participants = state.participants.filter(p => p.id !== action.payload.id);
    },
    resetPagination(state) {
      state.page = DEFAULT_PAGINATION.page;
      state.size = DEFAULT_PAGINATION.size;
    },
    addPage(state) {
      state.page = state.page + 1;
    },
  },
});

export const {
  addPage, addCommunityParticipants,
  resetPagination,
  resetPaginationAndParticipants,
  removeParticipant
} = communityParticipantsSlice.actions;

export default communityParticipantsSlice.reducer;
