enum SECURE_STORAGE_KEYS_ENUM {
  GZP_MOBILE_COMMUNITY_TAGS = 'GZP_MOBILE_COMMUNITY_TAGS',
  GZP_MOBILE_IS_LOGGED_IN = 'GZP_MOBILE_IS_LOGGED_IN',
  GZP_MOBILE_FIREBASE_TOKEN_REGISTERED = 'GZP_MOBILE_FIREBASE_TOKEN_REGISTERED',
  GZP_MOBILE_NEWS_TAGS = 'GZP_MOBILE_NEWS_TAGS',
  GZP_MOBILE_NEWS_EVENTS_TAGS = 'GZP_MOBILE_NEWS_EVENTS_TAGS',
  GZP_MOBILE_SELECTED_EMPLOYEE = 'GZP_MOBILE_SELECTED_EMPLOYEE',
  GZP_MOBILE_HOME_SWIPER = 'GZP_MOBILE_HOME_SWIPER',
  GZP_MOBILE_SURVEYS_FIRST_TIME_VISIT = 'GZP_MOBILE_SURVEYS_FIRST_TIME_VISIT',
  GZP_MOBILE_NOTIFICATIONS_FIRST_TIME_VISIT = 'GZP_MOBILE_NOTIFICATIONS_FIRST_TIME_VISIT',
  GZP_MOBILE_SURVEY_PROGRESS_ID = `GZP_MOBILE_SURVEY_PROGRESS_ID`,
  GZP_MOBILE_INSTALLED_PASS = 'GZP_MOBILE_INSTALLED_PASS',
  GZP_MOBILE_IS_TOUCH_INSTALLED = 'GZP_MOBILE_IS_TOUCH_INSTALLED',
  GZP_MOBILE_IS_FACE_INSTALLED = 'GZP_MOBILE_IS_FACE_INSTALLED',
  GZP_MOBILE_DAILY_NEXT_DATE_MOOD = 'GZP_MOBILE_DAILY_NEXT_DATE_MOOD',
  GZP_MOBILE_WEEKLY_NEXT_DATE_MOOD = 'GZP_MOBILE_WEEKLY_NEXT_DATE_MOOD',
  GZP_MOBILE_MOOD_SUBMITTED = 'GZP_MOBILE_MOOD_SUBMITTED',
  GZP_MOBILE_MOOD_CLOSED = 'GZP_MOBILE_MOOD_CLOSED',
  GZP_MOBILE_MOOD_CLOSED_TWICE = 'GZP_MOBILE_MOOD_CLOSED_TWICE',
  GZP_MOBILE_MOOD_SWIPED = 'GZP_MOBILE_MOOD_SWIPED',
  GZP_RATE_MODAL_SHOWED = 'GZP_RATE_MODAL_SHOWED',
  GZP_MOBILE_SAVED_AGREEMENTS = 'GZP_MOBILE_SAVED_AGREEMENTS',
}

export default SECURE_STORAGE_KEYS_ENUM;
