import React, { useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import {
  useGetUpdatedPolicyAgreementsQuery,
  useSendPolicyAgreementsMutation,
} from 'services/public/publicApiService';

import { Button, Checkbox, Form, Typography } from 'gazprom-ui-lib';

import s from './AgreementBottomSheet.module.scss';

const AgreementBottomSheet = () => {
  const { isAuthenticated } = useAuth();
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { data } = useGetUpdatedPolicyAgreementsQuery(undefined, {
    skip: !isAuthenticated,
  });
  const [sendPolicyAgreements, { isLoading }] = useSendPolicyAgreementsMutation();

  const onFinish = () => {
    if (data) {
      const transformedData = data.map((item) => ({ agreementId: item.id, hash: item.fileHash }));
      sendPolicyAgreements(transformedData);
    }
  };

  const { handleOpenFile } = useOpenFileByUrl();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const parent = target.parentNode as HTMLElement;
      if (target && parent.dataset.fileKey) {
        e.preventDefault();
        const fileKey = parent.dataset.fileKey;
        const fileName = parent.dataset.fileName;
        handleOpenFile({
          defaultFileName: `${fileName}.pdf`,
          url: createMediaDownloadUrl(`api/v1/media/download/${fileKey}`),
          fileKey: fileKey,
        });
      }
    };
    document.body.addEventListener('click', handleClick);

    return () => document.body.removeEventListener('click', handleClick);
  }, [handleOpenFile]);

  if (!data || data.length === 0) return null;

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <Typography.Title level={3}>{t('common_documents_updated')}</Typography.Title>
        <Form
          form={form}
          className={s.form}
          onFinish={onFinish}
          onFieldsChange={(_, fields) => {
            const isAllFieldsChecked =
              fields.filter((field) => field.value).length === data?.length;
            setIsButtonDisabled(!isAllFieldsChecked);
          }}>
          {data?.map((item) => (
            <Form.Item valuePropName="checked" name={item.id}>
              <Typography.Title level={5} strong={false}>
                <Checkbox className={s.checkboxTrans}>
                  <span
                    dangerouslySetInnerHTML={{ __html: item.description }}
                    data-file-key={item.fileKey}
                    data-file-name={item.name}
                  />
                </Checkbox>
              </Typography.Title>
            </Form.Item>
          ))}
          <Button
            htmlType="submit"
            rightIcon="arrowRight"
            size="large"
            fullWidth
            loading={isLoading}
            type="primary"
            disabled={isButtonDisabled}>
            {t('common_continue')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default AgreementBottomSheet;
