import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { LOGIN_ACTIONS_ROUTE } from 'routes/login/list';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { useGetPolicyAgreementsQuery } from 'services/public/publicApiService';

import { Button, Checkbox, Form, Typography } from 'gazprom-ui-lib';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import LoginWrapper from '../login-wrapper';
import s from './LoginTermsAndAgreements.module.scss';

const LoginTermsAndAgreements = () => {
  const [, setAgreements] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SAVED_AGREEMENTS,
    '',
  );

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { data } = useGetPolicyAgreementsQuery();
  const { handleOpenFile } = useOpenFileByUrl();

  const onFinish = () => {
    if (data) {
      const transformedData = data.map((item) => ({ agreementId: item.id, hash: item.fileHash }));
      setAgreements(JSON.stringify(transformedData));
    }

    navigate(LOGIN_ACTIONS_ROUTE);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const parent = target.parentNode as HTMLElement;
      if (target && parent.dataset.fileKey) {
        e.preventDefault();
        const fileKey = parent.dataset.fileKey;
        const fileName = parent.dataset.fileName;
        handleOpenFile({
          getFileExtensionFromResponse: true,
          defaultFileName: `${fileName}`,
          url: createMediaDownloadUrl(`api/v1/media/download/${fileKey}`),
          fileKey: fileKey,
        });
      }
    };
    document.body.addEventListener('click', handleClick);

    return () => document.body.removeEventListener('click', handleClick);
  }, [handleOpenFile]);

  return (
    <LoginWrapper>
      <Form
        form={form}
        className={s.form}
        onFinish={onFinish}
        onFieldsChange={(_, fields) => {
          const isAllFieldsChecked = fields.filter((field) => field.value).length === data?.length;
          setIsButtonDisabled(!isAllFieldsChecked);
        }}>
        {data?.map((item) => (
          <Form.Item valuePropName="checked" name={item.id}>
            <Typography.Title level={5} strong={false}>
              <Checkbox className={s.checkboxTrans}>
                <span
                  dangerouslySetInnerHTML={{ __html: item.description }}
                  data-file-key={item.fileKey}
                  data-file-name={item.name}
                />
              </Checkbox>
            </Typography.Title>
          </Form.Item>
        ))}
        <Button
          htmlType="submit"
          rightIcon="arrowRight"
          size="large"
          fullWidth
          type="primary"
          disabled={isButtonDisabled}>
          {t('common_continue')}
        </Button>
      </Form>
    </LoginWrapper>
  );
};

export default LoginTermsAndAgreements;
