import initialApiService from 'services/initialApiService';

import {
  GetPolicyAgreementsPropsType,
  GetPolicyAgreementsResponseType,
  GetSalaryProjectByIdPropsType,
  GetSalaryProjectByIdResponseType,
  GetSalaryProjectsPropsType,
  GetSalaryProjectsResponseType,
  GetUpdatedPolicyAgreementsPropsType,
  GetUpdatedPolicyAgreementsResponseType,
  SendPolicyAgreementsPropsType,
  SendPolicyAgreementsResponseType,
} from './publicApiService.types';

import { SERVICE_TAGS } from '../../../constants/serviceTags';
import { createPublicUrl } from './utils';

const publicApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSalaryProjects: builder.query<GetSalaryProjectsResponseType, GetSalaryProjectsPropsType>({
      query: () => ({
        url: createPublicUrl(`/salary-project`),
      }),
      keepUnusedDataFor: 0,
    }),
    sendPolicyAgreements: builder.mutation<
      SendPolicyAgreementsResponseType,
      SendPolicyAgreementsPropsType
    >({
      query: (body) => ({
        url: createPublicUrl(`/me/policy-agreement`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_USER_AGREEMENTS],
    }),
    getUpdatedPolicyAgreements: builder.query<
      GetUpdatedPolicyAgreementsResponseType,
      GetUpdatedPolicyAgreementsPropsType
    >({
      query: () => ({
        url: createPublicUrl(`/me/policy-agreement`),
      }),
      providesTags: [SERVICE_TAGS.GET_USER_AGREEMENTS],
    }),
    getPolicyAgreements: builder.query<
      GetPolicyAgreementsResponseType,
      GetPolicyAgreementsPropsType
    >({
      query: () => ({
        url: createPublicUrl(`/policy-agreement`),
      }),
    }),
    getSalaryProjectById: builder.query<
      GetSalaryProjectByIdResponseType,
      GetSalaryProjectByIdPropsType
    >({
      query: (id) => ({
        url: createPublicUrl(`/salary-project/${id}`),
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetUpdatedPolicyAgreementsQuery,
  useSendPolicyAgreementsMutation,
  useGetPolicyAgreementsQuery,
  useGetSalaryProjectByIdQuery,
  useGetSalaryProjectsQuery,
} = publicApiService;
