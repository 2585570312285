import { lazy } from 'react';

import { Surveys } from 'pages/surveys';

import { RouteConfig } from '../routes';
import {
  MORE_ABOUT_COMPANY_ROUTE,
  MORE_COMMUNITIES_ROUTE,
  MORE_COMMUNITY_DETAILS_DISCUSSIONS_DETAILS_ROUTE,
  MORE_COMMUNITY_DETAILS_DISCUSSIONS_ROUTE,
  MORE_COMMUNITY_DETAILS_PARTICIPANTS_ROUTE,
  MORE_COMMUNITY_DETAILS_ROUTE,
  MORE_COMMUNITY_DETAILS_SETTINGS_ROUTE,
  MORE_COMMUNITY_INVITES_ROUTE,
  MORE_DMS_ROUTE,
  MORE_EDUCATION_BDD_ROUTE,
  MORE_EDUCATION_FIRE_SECURITY_ROUTE,
  MORE_EDUCATION_MANUFACTURING_JOBS_ROUTE,
  MORE_EDUCATION_ROUTE,
  MORE_EDUCATION_WORK_SECURITY_ROUTE,
  MORE_FAQ_CATEGORY_DETAILS_ROUTE,
  MORE_FAQ_QUESTION_DETAILS_ROUTE,
  MORE_FAQ_ROUTE,
  MORE_FEEDBACK_CREATE_ROUTE,
  MORE_FEEDBACK_DETAILS_ROUTE,
  MORE_FEEDBACK_ROUTE,
  MORE_ROUTE,
  MORE_SUGGESTION_CHOOSE_CATEGORY_ROUTE,
  MORE_SUGGESTION_CORRECTION_ROUTE,
  MORE_SUGGESTION_CREATE_ROUTE,
  MORE_SUGGESTION_DECISION_DETAILS_ROUTE,
  MORE_SUGGESTION_DECISION_PERFORMER_ROUTE,
  MORE_SUGGESTION_DECISION_RESULT_ROUTE,
  MORE_SUGGESTION_DECISION_ROUTE,
  MORE_SUGGESTION_REQUEST_DETAILS_ROUTE,
  MORE_SUGGESTION_REQUEST_ROUTE,
  MORE_SUGGESTION_ROUTE,
  MORE_SURVEYS_ROUTE,
  MORE_SURVEY_ROUTE,
} from './list';

const More = lazy(() => import('pages/more'));
const GuideEducationDescription = lazy(() => import('pages/more/guide-education-description'));
const GuideEducationSection = lazy(() => import('pages/more/guide-education-section'));
const MoreAboutCompany = lazy(() => import('pages/more/more-about-company'));
const MoreDms = lazy(() => import('pages/more/more-dms'));
const MoreFaq = lazy(() => import('pages/more/more-faq'));
const MoreFaqCategoryDetails = lazy(() => import('pages/more/more-faq/more-faq-category-details'));
const MoreFaqCategoryDetailsQuestion = lazy(
  () => import('pages/more/more-faq/more-faq-question-details'),
);
const ProfileFeedback = lazy(() => import('pages/more/more-feedback'));
const FeedbackDetails = lazy(() => import('pages/more/more-feedback/feedback-details'));
const ProfileFeedbackCreate = lazy(
  () => import('pages/more/more-feedback/profile-feedback-create'),
);
const MoreSuggestions = lazy(() => import('pages/more/more-suggestion'));
const MoreSuggestionChooseCategory = lazy(
  () => import('pages/more/more-suggestion/more-suggestion-choose-category'),
);
const MoreSuggestionCreateSuggestion = lazy(
  () => import('pages/more/more-suggestion/more-suggestion-create'),
);
const MoreSuggestionDecision = lazy(
  () => import('pages/more/more-suggestion/more-suggestion-decision'),
);
const MoreSuggestionDecisionDetails = lazy(
  () =>
    import('pages/more/more-suggestion/more-suggestion-decision/more-suggestion-decision-details'),
);
const MoreSuggestionDecisionPerformer = lazy(
  () =>
    import(
      'pages/more/more-suggestion/more-suggestion-decision/more-suggestion-decision-performer'
    ),
);
const MoreSuggestionDecisionResult = lazy(
  () =>
    import('pages/more/more-suggestion/more-suggestion-decision/more-suggestion-decision-result'),
);
const SurveysSingleWrapper = lazy(() => import('pages/surveys/surveys-single-wrapper'));
const MoreCommunities = lazy(() => import('pages/more/more-communities'));
const MoreCommunitiesInvites = lazy(
  () => import('pages/more/more-communities/more-communities-invites/moreCommunitiesInvites'),
);

const MoreCommunitiesDiscussions = lazy(
  () => import('pages/more/more-communities/more-communities-discussions'),
);
const MoreCommunitiesSettings = lazy(
  () => import('pages/more/more-communities/more-communities-settings'),
);

const MoreCommunitiesParticipants = lazy(
  () => import('pages/more/more-communities/more-communities-participants'),
);

export const moreRoutes: RouteConfig[] = [
  {
    path: MORE_ROUTE,
    element: <More />,
  },
  {
    path: MORE_EDUCATION_ROUTE,
    element: <GuideEducationDescription />,
  },
  {
    path: MORE_FAQ_ROUTE,
    element: <MoreFaq />,
  },
  {
    path: MORE_FAQ_CATEGORY_DETAILS_ROUTE,
    element: <MoreFaqCategoryDetails />,
  },
  {
    path: MORE_FAQ_QUESTION_DETAILS_ROUTE,
    element: <MoreFaqCategoryDetailsQuestion />,
  },
  {
    path: MORE_EDUCATION_BDD_ROUTE,
    element: <GuideEducationSection />,
  },
  {
    path: MORE_EDUCATION_WORK_SECURITY_ROUTE,
    element: <GuideEducationSection />,
  },
  {
    path: MORE_EDUCATION_FIRE_SECURITY_ROUTE,
    element: <GuideEducationSection />,
  },
  {
    path: MORE_EDUCATION_MANUFACTURING_JOBS_ROUTE,
    element: <GuideEducationSection />,
  },
  {
    path: MORE_DMS_ROUTE,
    element: <MoreDms />,
  },
  {
    path: MORE_SURVEYS_ROUTE,
    element: <Surveys />,
  },
  {
    path: MORE_SURVEY_ROUTE,
    element: <SurveysSingleWrapper />,
  },

  {
    path: MORE_FEEDBACK_ROUTE,
    element: <ProfileFeedback />,
  },
  {
    path: MORE_FEEDBACK_DETAILS_ROUTE,
    element: <FeedbackDetails />,
  },
  {
    path: MORE_FEEDBACK_CREATE_ROUTE,
    element: <ProfileFeedbackCreate />,
  },
  {
    path: MORE_ABOUT_COMPANY_ROUTE,
    element: <MoreAboutCompany />,
  },
  {
    path: MORE_SUGGESTION_ROUTE,
    element: <MoreSuggestions />,
  },
  {
    path: MORE_SUGGESTION_CHOOSE_CATEGORY_ROUTE,
    element: <MoreSuggestionChooseCategory />,
  },
  {
    path: MORE_SUGGESTION_CREATE_ROUTE,
    element: <MoreSuggestionCreateSuggestion />,
  },
  {
    path: MORE_SUGGESTION_CORRECTION_ROUTE,
    element: <MoreSuggestionCreateSuggestion />,
  },
  {
    path: MORE_SUGGESTION_REQUEST_ROUTE,
    element: <MoreSuggestionDecision />,
  },
  {
    path: MORE_SUGGESTION_REQUEST_DETAILS_ROUTE,
    element: <MoreSuggestionDecisionDetails />,
  },
  {
    path: MORE_SUGGESTION_DECISION_ROUTE,
    element: <MoreSuggestionDecision />,
  },
  {
    path: MORE_SUGGESTION_DECISION_DETAILS_ROUTE,
    element: <MoreSuggestionDecisionDetails />,
  },
  {
    path: MORE_SUGGESTION_DECISION_PERFORMER_ROUTE,
    element: <MoreSuggestionDecisionPerformer />,
  },
  {
    path: MORE_SUGGESTION_DECISION_RESULT_ROUTE,
    element: <MoreSuggestionDecisionResult />,
  },
  {
    path: MORE_COMMUNITIES_ROUTE,
    element: <MoreCommunities />,
  },
  { path: MORE_COMMUNITY_DETAILS_ROUTE, element: <MoreCommunities /> },
  { path: MORE_COMMUNITY_INVITES_ROUTE, element: <MoreCommunitiesInvites /> },
  { path: MORE_COMMUNITY_DETAILS_DISCUSSIONS_ROUTE, element: <MoreCommunitiesDiscussions /> },
  {
    path: MORE_COMMUNITY_DETAILS_DISCUSSIONS_DETAILS_ROUTE,
    element: <MoreCommunitiesDiscussions />,
  },
  {
    path: MORE_COMMUNITY_DETAILS_SETTINGS_ROUTE,
    element: <MoreCommunitiesSettings />,
  },
  {
    path: MORE_COMMUNITY_DETAILS_PARTICIPANTS_ROUTE,
    element: <MoreCommunitiesParticipants />,
  },
  // { path: MORE_COMMUNITY_DETAILS_ROUTE, element: <HomeNews /> },
  // { path: HOME_NEWS_TAGS_ROUTE, element: <HomeNewsTags /> },
];
